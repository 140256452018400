<script setup lang="ts">
import { formatDate } from "@/utils/date";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import cleanDeep from "clean-deep";

const { $axios } = useNuxtApp();
const route = useRoute();

const queryClient = useQueryClient();
const $emit = defineEmits(["update"]);
const { stateOptions } = await useStateOptions();
const { countryOptions } = await useCountryOptions();

const form = ref({
  company_name: "",
  first_name: "",
  last_name: "",
  middle_name: "",
  initials: "",
  address_one: "",
  address_two: "",
  city: "",
  state: "",
  province: "",
  country: "US",
  postal_code: "",
  phone: "",
  email: "",
  dob: "",
  ssn: "",
});

const witnessAuth = ref("");
const auth = ref("");

const { mutate: add, isPending } = useMutation({
  mutationFn: async (data) =>
    await $axios
      .post(`/v1/clients`, {
        ...cleanDeep(form.value),
        dob: form.value.dob
          ? formatDate(form.value.dob, "YYYY-MM-DD")
          : undefined,
      })
      .then((res: any) => res.data),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: ["clients"] });
    $emit("update", false);
  },
  onError: (data) => {
    console.log(data);
  },
});
</script>
<template>
  <div class="p-8">
    <h1 class="mb-3 text-xl">Create Client</h1>
    <FormKit type="form" :actions="false" #="{ state: { valid } }">
      <div>
        <FormKit type="text" v-model="form.company_name" label="Company Name" />
      </div>
      <div class="grid grid-cols-4 gap-2">
        <FormKit
          type="text"
          v-model="form.first_name"
          validation="required"
          label="First"
        />
        <FormKit type="text" v-model="form.middle_name" label="Middle " />
        <FormKit
          type="text"
          v-model="form.last_name"
          validation="required"
          label="Last"
        />
        <FormKit type="text" v-model="form.initials" label="Initials" />
      </div>
      <div class="flex flex-col gap-2">
        <FormKit type="text" v-model="form.address_one" label="Address" />
      </div>
      <div class="grid grid-cols-2 gap-2">
        <FormKit
          type="text"
          v-model="form.address_two"
          label="Suite, Apt, etc."
        />
        <FormKit type="text" v-model="form.city" label="City" />
      </div>
      <div class="grid grid-cols-3 gap-2">
        <InputsSelect
          :options="stateOptions"
          label="State"
          v-model="form.state"
          v-if="form.country === 'US'"
        />
        <FormKit
          type="text"
          v-model="form.province"
          label="State/province"
          v-else
        />
        <InputsSelect
          :options="countryOptions"
          label="Country"
          v-model="form.country"
        />
        <FormKit type="text" v-model="form.postal_code" label="Postal Code" />
      </div>
      <div class="grid grid-cols-3 gap-2">
        <FormKit
          type="text"
          validation="required"
          v-model="form.email"
          label="Email"
        />
        <div class="col-span-2">
          <InputsMaskedPhone
            validation="required|validphone"
            v-model:phone="form.phone"
          />
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <FormKit
          type="mask"
          mask="##/##/####"
          v-model="form.dob"
          label="Date of Birth"
          :show-mask="false"
        />
        <FormKit
          type="mask"
          mask="###-##-####"
          allow-incomplete="true"
          v-model="form.ssn"
          unmask-value="true"
          :show-mask="false"
          label="Social Security Num."
        />
      </div>
      <div class="flex justify-end">
        <div class="flex gap-2">
          <CommonButton
            label="Cancel"
            type="minimal"
            color="midnight"
            rounded
            @click="$emit('update', false)"
          />
          <CommonButton
            label="Save"
            rounded
            :disabled="!valid || isPending"
            :loading="isPending"
            color="midnight"
            @click="add"
          />
        </div>
      </div>
    </FormKit>
  </div>
</template>
