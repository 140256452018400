<script setup lang="ts">
const { menubarBottom } = useSettings();
const nNotice = ref(false);
const nSearch = ref(false);
const auth = useAuth();

const config = useRuntimeConfig();
const addClient = ref(false);

const userMenu = ref(false);

const openChat = () => {
  window.$sleek.open();
};

function getStateAbbr(state: string) {
  switch (state) {
    case "TX":
      return "texas";
    case "FL":
      return "florida";
    case "AL":
      return "alabama";
    case "MS":
      return "mississippi";
    case "TN":
      return "tennessee";
      case "NY":
      return "new-york";
      case "NJ":
      return "new-jersey";
      case "IL":
      return "illinois";
      case "GA":
      return "georgia";
    default:
      return null;
  }
}

const gotoRecord = () => {
  const state = getStateAbbr(auth.user?.notary_detail?.state);

  if (state == null) {
    return navigateTo("/hub/sessions");
  }
  return navigateTo(`/records/${state}`);
};

function navigateStateRecord() {
  const state = getStateAbbr(auth.user?.notary_detail?.state);
  if (state == null) {
    return navigateTo("/hub/sessions");
  }
  return navigateTo(`/records/${state}/create`);
}
</script>
<template>
  <div class="sticky z-50 h-[62px] flex justify-center w-full top-3">
    <nav
      class="w-[68vw] min-w-[300px] h-[62px] shadow-ui bg-[#101827] border border-gray-800 p-4 rounded text-white flex justify-between items-center"
    >
      <div class="flex items-center w-full">
        <SystemLogoN class="mr-3 w-7 text-ngreen" />
        <div class="hidden md:block">
          <ul class="menu-items">
            <li @click="navigateTo('/hub/sessions')">Sessions</li>
            <li @click="navigateTo('/hub/clients')">Clients</li>
            <li v-if="auth?.user?.is_notary" @click="gotoRecord()">Records</li>
            <!-- <li>
              <CommonPopover :side-offset="25">
                <Icon
                  name="heroicons:ellipsis-horizontal-20-solid"
                  class="mt-1 text-2xl"
                />
                <template #title> Other Menu Options </template>
                <template #content>
                  <ul class="submenu-items">
                    <li>Client Billing</li>
                    <li>Notary Scholar</li>
                    <li>Notary Application</li>
                  </ul>
                </template>
              </CommonPopover>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="flex items-center justify-center gap-3">
        <!-- Mobile Menu -->
        <div class="block md:hidden">
          <PopoverRoot>
            <PopoverTrigger>
              <div class="flex items-center justify-center">
                <Icon
                  name="heroicons:ellipsis-horizontal-20-solid"
                  class="mt-1 text-2xl"
                />
              </div>
            </PopoverTrigger>
            <PopoverContent alignment="center" :side-offset="25">
              <!-- Content -->
              <nav
                class="w-[300px] min-h-[50px] dark:text-white bg-midnight rounded"
              >
                <div class="flex items-center px-3 py-4 bg-[#10151C] rounded-t">
                  <div class="flex items-center text-[#788291] text-sm">
                    Menu Options
                  </div>
                </div>
                <div class="p-3">
                  <ul class="submenu-items">
                    <li @click="navigateTo('/hub/sessions')">Sessions</li>
                    <li @click="navigateTo('/hub/clients')">Clients</li>
                    <li
                      v-if="auth?.user?.is_notary"
                      @click="navigateTo('/records/texas')"
                    >
                      Records
                    </li>
                  </ul>
                </div>
              </nav>
            </PopoverContent>
          </PopoverRoot>
        </div>
        <div>
          <CommonPopover :side-offset="25" alignment="center">
            <Icon name="heroicons:plus-circle" class="text-3xl text-ngreen" />
            <template #title> Quick Create </template>
            <template #content>
              <ul class="submenu-items">
                <li @click="navigateTo('/hub/sessions/create')">
                  <Icon name="heroicons-outline:play" class="-mt-1 text-lg" />
                  Create Session
                </li>
                <li @click="addClient = true">
                  <Icon name="heroicons:user" class="-mt-1 text-lg" />
                  Add Client
                </li>
                <li v-if="auth?.user?.is_notary" @click="navigateStateRecord">
                  <Icon
                    name="heroicons-outline:book-open"
                    class="-mt-1 text-lg"
                  />
                  Create Notary Record
                </li>
                <!-- <li>
                  <Icon
                    name="heroicons-outline:credit-card"
                    class="-mt-1 text-lg"
                  />
                  Create Client Bill
                </li> -->
              </ul>
            </template>
          </CommonPopover>
        </div>
        <!-- <div @click="nSearch = true" class="cursor-pointer">
          <Icon name="heroicons-outline:search" class="text-[20px]" />
        </div>
        <div @click="nNotice = true" class="cursor-pointer">
          <Icon name="heroicons-outline:bell" class="text-[20px]" />
        </div> -->
        <div class="">
          <CommonPopover :side-offset="25">
            <!-- Action Button -->
            <div class="flex items-center">
              <AvatarRoot
                class="bg-primaryBG inline-flex h-[32px] w-[32px] select-none items-center justify-center overflow-hidden rounded-full align-middle"
              >
                <AvatarImage
                  class="h-full w-full rounded-[inherit] object-cover"
                  :img="auth?.user?.avatar"
                  :alt="auth?.user?.first_name + ' ' + auth?.user?.last_name"
                />
                <AvatarFallback
                  class="text-grass11 uppercase leading-1 text-black flex h-full w-full items-center justify-center bg-[#F3F4F6] text-[12px] font-medium"
                  :delayMs="600"
                >
                  {{
                    auth?.user?.first_name?.slice(0, 1) +
                    auth?.user?.last_name?.slice(0, 1)
                  }}
                </AvatarFallback>
              </AvatarRoot>
              <Icon
                name="heroicons:ellipsis-vertical-20-solid"
                class="mt-1 text-2xl"
              />
            </div>
            <!-- Content -->
            <template #title>
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center gap-2 text-sm">
                  <AvatarRoot
                    class="bg-primaryBG mr-4 border border-solid border-gray-400/40 inline-flex h-[40px] w-[40px] select-none items-center justify-center overflow-hidden rounded-full align-middle"
                  >
                    <AvatarImage
                      class="h-full w-full rounded-[inherit] object-cover"
                      :img="auth?.user?.avatar"
                      :alt="
                        auth?.user?.first_name + ' ' + auth?.user?.last_name
                      "
                    />
                    <AvatarFallback
                      class="text-grass11 uppercase leading-1 text-black flex h-full w-full items-center justify-center bg-[#F3F4F6] text-[14px] font-medium"
                      :delayMs="600"
                    >
                      {{
                        auth?.user?.first_name?.slice(0, 1) +
                        auth?.user?.last_name?.slice(0, 1)
                      }}
                    </AvatarFallback>
                  </AvatarRoot>
                  <div class="text-sm font-semibold">
                    <div>
                      {{ auth?.user?.first_name }} {{ auth?.user?.last_name }}
                    </div>
                    <div class="text-[#788291] text-xs">
                      {{ auth.account.company_name }}
                    </div>
                  </div>
                </div>
                <div @click="auth.logout()">
                  <Icon
                    name="humbleicons:logout"
                    class="mr-2 text-xl cursor-pointer"
                  />
                </div>
              </div>
            </template>
            <template #content>
              <ul class="submenu-items">
                <li @click="navigateTo(`/user/edit`)">
                  <Icon name="heroicons:user" class="-mt-1 text-lg" />
                  Edit Profile
                </li>
                <li
                  @click="navigateTo('/hub/account')"
                  v-if="auth?.user?.roles?.[0]?.name === 'Owner'"
                >
                  <Icon
                    name="heroicons:building-office"
                    class="-mt-1 text-lg"
                  />
                  Account
                </li>
                <li
                  @click="
                    navigateTo('https://notaryio.customerly.help', {
                      external: true,
                      open: {
                        target: '_blank',
                      },
                    })
                  "
                >
                  <Icon
                    name="heroicons-outline:bookmark-alt"
                    class="-mt-1 text-lg"
                  />
                  Knowledge Base
                </li>
                <li @click="openChat()">
                  <Icon name="humbleicons:support" class="-mt-1 text-lg" />
                  Support / Feedback
                </li>
                <li @click="toggleDark()">
                  <Icon
                    v-if="isDark"
                    name="heroicons:sun-20-solid"
                    class="-mt-1 text-lg"
                  />
                  <Icon
                    name="heroicons:moon-solid"
                    v-else
                    class="-mt-1 text-lg"
                  />
                  {{ isDark ? "Light Mode" : "Dark Mode" }}
                </li>
              </ul>
              <div
                class="flex items-center justify-between px-2 py-4 rounded-b cursor-pointer"
                @click="
                  navigateTo(`https://app.${config.public.domain}/switch`, {
                    external: true,
                  })
                "
              >
                <div class="flex items-center text-sm">
                  <Icon
                    name="heroicons-outline:arrows-right-left"
                    class="mr-2 text-lg"
                  />
                  <div class="text-sm font-semibold">Switch Account</div>
                </div>
              </div>
            </template>
          </CommonPopover>
        </div>
      </div>
    </nav>
    <USlideover v-model="nNotice">
      <!-- Content -->
      <div>
        <div>
          <h2>Platform Updates</h2>
        </div>
        <div>
          <h2>Notifications</h2>
        </div>
      </div>
    </USlideover>
    <!-- Search -->
    <UModal v-model="nSearch">
      <UCommandPalette
        v-model="selected"
        multiple
        nullable
        :groups="[{ key: 'people', commands: people }]"
      />
    </UModal>
    <UModal v-model="addClient">
      <HubQuickClient @update="addClient = $event" />
    </UModal>
  </div>
</template>
<style scoped>
.menu-items {
  @apply mt-0 pt-0 flex flex-row items-center align-middle justify-between gap-4 text-sm font-medium;
}
.menu-items li {
  @apply cursor-pointer;
}
.submenu-items {
  @apply mt-0 pt-0 flex flex-col items-start align-middle justify-between gap-2 text-sm text-white w-full;
}
.submenu-items li {
  @apply py-2 px-4 cursor-pointer text-sm w-full;
}
.submenu-items li:hover {
  @apply bg-[#10151C] dark:bg-[#10151C] rounded w-full;
}
</style>
